.ant-modal.schedule-dlg {
    top:12% !important;
    width: 687px !important;
}
.ant-modal.schedule-dlg .ant-modal-title {
    font-family: mediumFont;
    font-size: 18px;
}
.ant-modal.schedule-dlg .ant-modal-content{
    border: 15px solid var(--whiteColor);
    border-radius: 10px;
}
.ant-modal.schedule-dlg .ant-modal-header {
    border-bottom: none;
}
.ant-modal.schedule-dlg .ant-modal-footer {
    border-top: none;
}
.ant-modal.schedule-dlg .normal-input {
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 8px 12px;
}
.ant-modal.schedule-dlg .ant-btn {
    width: 100px;
    height: 40px;
    border-radius: 8px;
}
.ant-modal.schedule-dlg .ant-modal-body {
    padding: 10px 24px 24px 24px;
}

.day-rect {
    padding: 6px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    width: 80px;
    text-align: center;
    cursor: pointer;
}
.day-rect.active{
    background-color: var(--mainColor);
    color: var(--whiteColor);
}
.time-parent-rect {
    border: 1px solid var(--mainColor);
    margin-top: 10px;
}
.time-rect {
    padding: 4px 0px;
    background: var(--mainColor);
    color: var(--whiteColor);
}
.black-day-item {
    margin-right: 10px;
    display: inline-block;
    border: 1px solid #dddddd;
    border-radius: 3px;
    padding: 3px 10px;
    background: white;
    height: 30px;
    margin-top: 5px;
}
.black-day-item-delete-button {
    cursor: pointer;
    color: red;
}
.black-day-item-delete-button:hover {
    color: rgb(250, 99, 99);
}
.black-day-item-delete-button:active {
    color: rgb(163, 4, 4);
}
