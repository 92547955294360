.auth-dialog-widget {
  width: 450px;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  margin: auto;
}
.auth-logo {
  width: 200px;
  height: auto;
  padding-top: 100px;
  margin-bottom: 40px;
}
.auth-dialog-widget > .title {
  font-size: 24px; 
  font-family: semiBoldFont;
  margin-top: 30px;
  color: var(--mainColor);
}

.auth-dialog-widget > .sub_title{
  font-size: 24px;
  font-family: semiBoldFont;
  margin-bottom: 30px;
  color: var(--mainColor);
}


@media screen and (max-width: 576px) {
  .auth-dialog-widget {
    margin-top: 0px;
    width: 100%;
    min-width: 100%;
  }

  .auth-dialog-widget > .title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .auth-logo {
    width: 120px;
    height: auto;
    padding-top: 60px;
    margin-bottom: 20px;
  }
}
