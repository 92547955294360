.home-setting .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #60A8DC;
}
.home-setting .one-editor-rect {
    margin-top: 5px;
    height: 400px;
    max-width: 100%;
    border: 1px solid rgb(217, 217, 217);
}
.home-setting .editorOneClassName {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    max-height: 320px;
    height: 320px;
    overflow-y: auto;
}