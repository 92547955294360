.code-verification-explain {
  font-family: regularFont;
  font-size: 16px;
  width: 90%;
  margin: auto;
}
.activation-code-input {
  max-width: 450px !important;
  margin: auto !important;
  margin-top: 20px !important;
}
.activation-code-input > * input {
  width: 60px !important;
  height: 60px !important;
  margin: unset !important;
  text-align: center !important;
  font-family: semiBoldFont !important;
  font-size: 32px !important;
  padding: unset !important;
}
.activation-code-input > .react-code-input {
  display: flex !important;
  justify-content: space-between !important;
}

@media screen and (max-width: 1024px) {
  .activation-code-input {
    max-width: 350px !important;
    margin-top: 20px !important;
  }
  .activation-code-input > * input {
    width: 50px !important;
    height: 50px !important;
    font-size: 26px !important;
  }
}
@media screen and (max-width: 768px) {
  .activation-code-input {
    max-width: 300px !important;
    margin-top: 10px !important;
  }
  .activation-code-input > * input {
    width: 35px !important;
    height: 35px !important;
    font-size: 20px !important;
  }
}
