.input-password>.ant-input-password {
  height: 46px !important;
  border-radius: 10px !important;
  background-color: white !important;
  padding-left: 10px;
  padding-right: 10px;
  font-family: regularFont;
  font-size: 16px !important;
}

.input-password>* .ant-input {
  font-family: regularFont;
  font-size: 16px !important;
}

.phone-input-password-wrong {
  border: 1px solid var(--redColor) !important;
}

.phone-input-password-correct {
  border: 1px solid var(--greyColor) !important;
}

.input-password .ant-input-affix-wrapper {
  height: 40px !important;
  border-radius: 16px !important;
  background-color: #7FB522 !important;
  padding: 0;
  font-family: regularFont;
  font-size: 16px !important;
}

.input-password .ant-input-prefix {
  margin-left: 4px;
  margin-right: 0px;
  padding: 0px 10px;
}

.input-password .ant-input-affix-wrapper>input.ant-input {
  padding-left: 8px;
}

.input-password .ant-input-suffix {
  padding-right: 12px;
  margin-left: 0px;
  background: white;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}


@media screen and (max-width: 768px) {
  .input-password .ant-input-affix-wrapper {
    height: 36px !important;
    border-radius: 16px !important;
    background-color: #7FB522 !important;
    padding: 0;
    font-family: regularFont;
    font-size: 14px !important;
  }

  .input-password .ant-input-prefix {
    margin-left: 4px;
    margin-right: 0px;
    padding: 0px 10px;
  }

  .input-password .ant-input-affix-wrapper>input.ant-input {
    padding-left: 8px;
  }

  .input-password .ant-input-suffix {
    padding-right: 12px;
    margin-left: -1px;
    background: white;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
  }
}