
/* .ant-table-row {
    color: var(--mainColor);
} */
.pTable .ant-table-thead > tr > th {
    font-size: 0.9rem;
}
.club-table-row {
    /* background-color: #bee5fa; */
    /* color: rgb(148, 32, 32); */
    /* color: var(--blueColor); */
    font-size: 0.8rem;
    /* font-weight: 600; */
}
/* .club-table-row:hover {
    background-color: #bee5fa;
}
.club-table-row:hover td {background: #bee5fa!important;} */
.child-table-row {
    font-size: 0.8rem;
}
