.club-box .ant-select.select-club {
  border: 1px solid var(--blueColor);
  border-radius: 4px;
  background: var(--whiteColor);
  padding: 0px;
  width: 100%;
  color: var(--blueColor);
  box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
}

.club-box .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 16px;
}

.club-box .ant-select-focused {
  border: 1px solid #8bc4e8 !important;
  box-shadow: 0 0 0 2px rgb(96 168 220 / 40%);
}

.club-box .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  background-color: var(--whiteColor);
  padding-left: 0px;
}

.club-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0px;
}

.club-box .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  box-shadow: none;
  padding-left: 0px;
}

.club-box .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0px;
  padding-left: 12px;
}

.club-box .ant-select-arrow {
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: var(--blueColor);
}

.club-box .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.club-box .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0px;
  padding-left: 12px;
  color: var(--blueColor);
  opacity: 1;
}

.club-name {
  font-family: mediumFont;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 20px;
}