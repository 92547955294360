.right-detail-drawer .ant-drawer-content-wrapper {
    width: 400px !important;
}
.right-detail-drawer .ant-drawer-title {
    font-size: 16px;
    font-weight: 600;
}
.right-detail-drawer .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    background: var(--backgroundColor);
}
.custom-input {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 8px 12px
}
.custom-input .ant-input {
    background: var(--backgroundColor);
}
.right-detail-drawer {
    font-weight: 600;
}
.right-detail-drawer .title {
    font-size: 20px;
}
.right-detail-drawer .ant-divider-horizontal {
    margin: 3px 0px;
}
.right-detail-drawer .block .title {
    font-size: 16px;
    color: var(--blueColor);
}
.right-detail-drawer .block .sub-title {
    font-size: 14px;
}
.right-detail-drawer .avatar-widget {
    height: 150px !important;
    min-height: 150px !important;
    margin: auto;
    border-radius: 14px;
    background-color: #fff;
    padding: 5px;
    border: 1px solid var(--greyColor);
}
.right-detail-drawer .avatar-widget-error {
    border-radius: 14px;
    border: 1px solid #ff8787;
}
.phone-custom-input {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 2px 6px;
    font-size: 12px;
}

.phone-custom-input-error {
    border: 1px solid #ff8787;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 6px 6px;
    font-size: 12px;
}


.phone-custom-input .ant-input {
    background: var(--backgroundColor);
}

.phone-custom-input .PhoneInputInput {
    border:none;
    outline: none;
    height: 26px;
    background-color: var(--backgroundColor);
}
.phone-custom-input .PhoneInputInput:focus-visible {
    border:none;
    outline: none;
}
/* .trade-box.trade-box-ex-container .trade-form .percentage .per-item:hover {
    border-color: #24a0f5;
  } */
  
.phone-custom-input.PhoneInput.PhoneInput--focus {
    border: 1px solid var(--blueColor);
}
.phone-custom-input.PhoneInput.PhoneInput:hover {
    border: 1px solid var(--blueColor);
    box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
}
.phone-custom-input.PhoneInput .PhoneInputInput[disabled] {
    cursor: not-allowed;
    opacity: 1;
}
.PhoneInputCountrySelectArrow {
    display: none;
}
.PhoneInputCountryIcon {
    width: 33px !important;
    height: 22px !important;
}
.PhoneInputInput {
    border: none;
    background: transparent;
}
.PhoneInput:focus-visible {
    outline: none;
}
.PhoneInputInput:focus-visible {
    outline: none;
}
@media screen and (max-width:768px) {
 
}