/* ::-webkit-scrollbar {
    width: 12px;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0);
}  */

.ant-table-tbody > tr > td {
    border-bottom: none;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    overflow-wrap: break-word;
}
.ant-table-thead > tr > th {
    font-weight: 700;
    color: rgba(0,0,0,.85);
    background: var(--backgroundColor);
}
.ant-table-tbody>tr.ant-table-row:hover>td {
    background: var(--backgroundColor);
}
.table-row-light {
    background-color: #ffffff;
}
/* .ant-table-cell-fix-left, .ant-table-cell-fix-right {
    background: transparent;
} */
.table-row-dark {
    background-color:var(--backgroundColor);
}
.ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.ant-table-row-expand-icon::after {
    left: 6px;
    bottom: 1px;
}

.ant-tabs-card.ant-tabs-top
> .ant-tabs-nav
.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
border-bottom-color: var(--mainColor);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
color: white;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
color: var(--whiteColor);
background: var(--mainColor);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
border-top-left-radius: 8px !important;
border-top-right-radius: 8px !important;
border-color: var(--mainColor) !important;
}

.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
padding: 16px;
background: #fff;
}
.ant-tabs-card > .ant-tabs-nav::before {
display: none;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
border: 1px solid #43aae1;
border-bottom-left-radius: 6px;
border-top-left-radius: 6px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
border: 1px solid #43aae1;
border-bottom-left-radius: 6px;
border-top-left-radius: 6px;
}

.ant-btn-primary {
    background: var(--mainColor);
    border-color: var(--mainColor);
}
.common-input {
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px !important;
    background: var(--backgroundColor);
    padding: 6px 6px;
    font-size: 12px;
}

.common-input-error {
    border: 1px solid #ff8787 !important;
    border-radius: 4px !important;
    background: var(--backgroundColor);
    padding: 6px 6px;
    font-size: 12px;
}

.common-input .ant-input {
    background: var(--backgroundColor);
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
    background-color: var(--backgroundColor);
}
.common-select {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 2px 0px;
    font-size: 12px;
}
.ant-input-affix-wrapper {
    border-radius: 4px;
}
.common-select:hover {
    border-color: #8bc4e8;
    border-right-width: 1px !important;
}

.common-select:focus {
    border-color: #8bc4e8;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
}

.common-select-error {
    width: 100%;
    border: 1px solid #ff8787 !important;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 1px 0px;
    font-size: 12px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
    border: none;
    background: var(--backgroundColor);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: 400;
}
.group-input .ant-input, .common-select {
    background: var(--backgroundColor);
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    background: #97cd39;
    border-color: #97cd39;
}
.ant-table-row-expand-icon::before {
    top: 8px;
    right: 2px;
    left: 1px;
    height: 1px;
}
.ant-table-row-expand-icon::after {
    left: 7px;
    bottom: 1px;
}
.group-select {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 1px 0px;
    font-size: 12px;
}

.group-select:hover {
    border-color: #8bc4e8;
    border-right-width: 1px !important;
}

.group-select:focus {
    border-color: #8bc4e8;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
}

.group-select-error {
    width: 100%;
    border: 1px solid #ff8787 !important;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 1px 0px;
    font-size: 12px;
}
.group-select .ant-select-selector {
    border: none !important;
    background: var(--backgroundColor) !important;
    height: 30px !important;
}
.group-select-error .ant-select-selector {
    border: none !important;
    background: var(--backgroundColor) !important;
    height: 30px !important;
}
.ant-table-row-expand-icon-cell {
    width: 40px !important;
    min-width: 40px !important;
}
.ant-table-row-action {
    width: 140px !important;
    min-width: 140px !important;
}
/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
    border: none;
    background: var(--backgroundColor);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: 400;
} */
