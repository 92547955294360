.ant-modal.message-detail-dlg {
    top:10% !important;
    width: 60% !important;
}
.ant-modal.message-detail-dlg .ant-modal-title {
    font-family: mediumFont;
    font-size: 18px;
}
.ant-modal.message-detail-dlg .ant-modal-content{
    border: 15px solid var(--whiteColor);
    border-radius: 10px;
}
.ant-modal.message-detail-dlg .ant-modal-header {
    border-bottom: none;
}
.ant-modal.message-detail-dlg .ant-modal-footer {
    border-top: none;
}
.ant-modal.message-detail-dlg .normal-input {
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 8px 12px;
}
.ant-modal.message-detail-dlg .ant-btn {
    width: 100px;
    height: 40px;
    border-radius: 8px;
}
.ant-modal.message-detail-dlg .ant-modal-body {
    padding: 10px 24px 24px 24px;
}

.ant-modal.message-detail-dlg .editor-rect {
    margin-top: 10px;
    height: 400px;
    max-width: 100%;
    border: 1px solid rgb(217, 217, 217);
}
.ant-modal.message-detail-dlg .editorClassName {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    max-height: 300px;
    height: 300px;
    overflow-y: auto;
}