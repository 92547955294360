.avatar-widget {
  background-color: var(--whiteColor);
  min-height: 30vh;
  text-align: center;
}
.avatar-container {
  /* background-color: var(--blueColor); */
  max-width: 1200px;
  margin: auto;
  position: relative;
  text-align: center;
}
.avatar-image-container {
  position: absolute;
  bottom: -75px;
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 14px;
  left: 0px;
  right: 0px;
  background-color: white;
  padding: 5px;
  border: 1px solid var(--greyColor);
}
.avatar-image {
  width: 140px;
  height: 140px;
  border-radius: 14px;
  object-fit: cover;
}
.background-edit-button {
  background-color: var(--backgroundColor);
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  font-family: mediumFont;
}
.avatar-edit-button-container {
  position: relative;
}
.avatar-edit-button {
  position: absolute;
  right: 0px;
  bottom: 5px;
  background-color: var(--backgroundColor);
  padding: 3px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid var(--greyColor);
  font-size: 18px;
  transition: all 500ms ease 0s;
  transform: scale(1);
}
.avatar-edit-button {
  box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
}
.user-name-container {
  margin-top: 80px;
  font-size: 24px;
  font-family: mediumFont;
}

.ant-tabs-tab-active {
  color: var(--blueColor) !important;
}
@media screen and (max-width: 768px) {
  .background-edit-button {
    bottom: unset;
    right: 10px;
    top: 10px;
  }
}
