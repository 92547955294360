.ant-modal.practice-dlg {
    top:20% !important;
    width: 40% !important;
}
.ant-modal.practice-dlg .ant-modal-title {
    font-family: mediumFont;
    font-size: 18px;
}
.ant-modal.practice-dlg .ant-modal-content{
    border: 15px solid var(--whiteColor);
    border-radius: 10px;
}
.ant-modal.practice-dlg .ant-modal-header {
    border-bottom: none;
}
.ant-modal.practice-dlg .ant-modal-footer {
    border-top: none;
}
.ant-modal.practice-dlg .normal-input {
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 8px 12px;
}
.ant-modal.practice-dlg .ant-btn {
    width: 100px;
    height: 40px;
    border-radius: 8px;
}
.ant-modal.practice-dlg .ant-modal-body {
    padding: 10px 24px 24px 24px;
}
.ant-modal.practice-dlg .ant-modal-close {
    display: none;
}

.event-type.ant-input[disabled] {
    color: var(--blackColor);
}
.no-repeat-practice {
    padding: 6px 20px;
    background-color: var(--mainColor);
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
@media screen and (max-width: 1390px) {
    .ant-modal.practice-dlg {
        top:20% !important;
        width: 60% !important;
    }
}
  