.green-button {
  width: 100%;
  height: 40px;
  border-radius: 14px !important;
  font-family: mediumFont;
  font-size: 20px;
  color: var(--whiteColor);
  border: 0px solid transparent;
}
.auth-dialog-widget .ant-spin-blur {
  display: none;
}
.auth-dialog-widget .ant-spin-dot-item {
	background: #ffffff
}
@media screen and (max-width: 1024px) {
  .green-button {
    height: 40px;
    border-radius: 16px !important;
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .green-button {
    height: 36px;
    border-radius: 16px !important;
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
  }
}
