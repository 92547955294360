.ant-layout-sider-children .logo {
    cursor: pointer;
    /* margin-left: 40px;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-right: 15px; */
    width: 95%;
    height: auto;
    text-align: center;
}
.ant-layout-sider-children .logo .logo-img {
    width: 100%;
    margin-left: 10px;
    padding: 32px 30px 15px 30px;
}
.ant-divider-horizontal {
    margin: 20px 0px;
}
.ant-layout-sider-children .logo .favicon-img {
    width: 100%;
    margin-left: 5px;
    padding: 15px 10px 0px 10px;
}
.side-collapse {
    z-index: 1;
    top: 38px;
    left: 100%;
    width: 56px;
    height: 56px;
    cursor: pointer;
    border: 8px solid var(--backgroundColor);
    border-radius: 100px;
    margin-left: -28px;
    background: var(--whiteColor);
    line-height: 40px;
    position: absolute;
    text-align: center;
    border-radius: 50px;
}

.custom-sidebar{
    flex: none !important;
    height: 100vh;
    width: 280px !important;
    max-width: 280px !important;
    background-color: var(--whiteColor);
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
}
.ant-layout-sider-collapsed.custom-sidebar {
    max-width: 142px !important;
    min-width: 142px !important;
    width: 142px !important;
}
.ant-menu.c-menu {
    width: 100% !important;
    padding: 0px 25px 25px;
    font-family: regularFont;
    font-size: 16px;
    color: var(--blackColor);
}
.ant-menu.c-menu.ant-menu-inline {
    border-right: 0px;
}
.ant-menu-inline .c-menu-item.ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
}
.ant-menu:not(.ant-menu-horizontal) .c-menu-item.ant-menu-item-selected {
    background-color: var(--mainColor);
    padding-left: 24px;
    color: var(--whiteColor);
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .c-menu-item.ant-menu-item::after, .ant-menu-vertical-right .c-menu-item.ant-menu-item::after, .ant-menu-inline .c-menu-item.ant-menu-item::after {
    border-right: 0px;
}
.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .c-menu-item.ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 60px;
    line-height: 64px;
    padding-left: 20px;
    border-radius: 12px;
}
.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 34px !important;
}
.menu-item-icon.ant-menu-item-icon{
    min-width: 22px !important;
    margin-right: 15px !important;
    font-size: 22px !important;
}
@media screen and (max-width: 1024px) {
    .custom-sidebar{
        width: 100% !important;
        max-width: 100% !important;
        background-color: var(--whiteColor);
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
    .side-collapse {
        left:90%;
    }
    .ant-layout .logo {
        width: 40%;
        padding: 25px;
        margin: auto;
    }
    .ant-drawer-body .logo {
        margin-top: 8px;
        width: 90%;
        margin-left: 15.5px;
        padding: 0px;
        padding-bottom: 15px;
    }
    .ant-menu.c-menu {
        width: 100% !important;
        padding: 0px;
        font-family: regularFont;
        font-size: 16px;
        color: var(--blackColor);
    }
    .logo-img {
        width: 100%;
        margin-left: 10px;
        padding: 32px 30px 15px 30px;
    }
}
@media screen and (max-width: 768px) {
    .side-collapse {
        z-index: 1;
        top: 5px;
        right: 0;
        left: 90%;
        width: 56px;
        height: 56px;
        cursor: pointer;
        border: 8px solid var(--backgroundColor);
        border-radius: 100px;
        margin-left: -28px;
        background: var(--whiteColor);
        line-height: 40px;
        position: absolute;
        text-align: center;
        border-radius: 50px;
    }
    .ant-layout .logo {
        width: 90%;
        margin-top: 10px;
        padding: 25px;
        margin-left: 45px;
        max-width: 280px;
        text-align: center;
        display: inherit;
    }
    /* .ant-drawer-body .logo {
        width: 90%;
        margin-left: 20px;
        padding: 0px;
    } */
    .ant-menu.c-menu {
        width: 100% !important;
        padding: 0px;
        font-family: regularFont;
        font-size: 16px;
        color: var(--blackColor);
    }
    .logo-img {
        width: 100%;
        margin-left: 10px;
        padding: 32px 30px 15px 30px;
    }
}