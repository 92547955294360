.main-background-rect {
  color: var(--blackColor);
  background-size: cover !important;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat !important;
}
.logo-rects {
  padding-left: 50px;
  padding-right: 50px;
}
.email-input .ant-input-affix-wrapper {
  height: 40px !important; 
  border-radius: 16px !important;
  background-color: #7FB522 !important;
  padding: 0;
  font-family: regularFont;
  font-size: 16px !important;
}
.email-input .ant-input-prefix {
  margin-left: 4px;
  margin-right: 0px;
  padding: 0px 10px;
}
.email-input .ant-input-affix-wrapper > input.ant-input {
  padding-left: 8px;
  font-size: 16px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}
@media screen and (max-width: 576px) {
  .logo-rects {
    padding-left: 10px;
    padding-right: 0px;
  }
  .email-input .ant-input-affix-wrapper {
    height: 36px !important;
    font-size: 14px !important;
  }
}
